.inner-text {
  text-align: left;
  p {
    text-align: left;
    margin: 20px 0 0 0;
    color: #595959;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }
  h2 {
    color: #253575;
    font-size: 22px;
    margin: 26px 0 0 0;
  }

  ul {
    margin: 20px 0 0 0;
    padding: 0 0 0 18px;

    li {
      color: #595959;
      font-size: 15px;
      font-weight: 300;
      list-style: initial;
      line-height: 28px;

      .in-heading {
        color: #253575 !important;
        font-size: 16px !important;
        font-family: var(--rubik);
        font-weight: bold !important;
        padding-bottom: 0px!important;
        margin-bottom: 0px!important;
      }
      .in__heading__p{
        margin: 0!important;
      }
    }
  }
}

.service-list {
  margin: 28px 0 0 0;
  background: #253575;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      .active {
        background: #0c1e67;

        @media (max-width: 991px) {
          border-radius: 0% !important;
        }
      }
      &:hover {
        background: #0c1e67;
      }
      a {
        text-decoration: none;
        padding: 10px 15px;
        border-bottom: 1px solid #0c1e67;
        color: #fff;
        display: block;
      }
    }
  }
}
