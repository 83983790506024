.inner__text {
  h2 {
    color: #253575;
    font-size: 24px;
    font-family: var(--rubik);
    font-weight: 800;

    @media only screen and (min-width: 768px) {
      font-size: 1.9rem !important;
      text-align: center!important;
    }

    @media only screen and (max-width: 600px) {
      font-size: 1.8rem !important;
      text-align: center!important;
    }
  }
  p {
    text-align: left;
    margin: 20px 0 20px 0;
    color: #595959;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }
}

.about__col{
    float: left!important;
}