.main__col {
  h5 {
    font-family: var(--rubik);
    color: #000;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
  }
  .about_para {
    color: #828282;
    font-family: var(--rubik);
    font-style: normal;
    font-weight: 400;
    padding-top: 10px!important;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.25em;
  }
}

.list-unstyled {
  font-family: var(--font-base);

  li {
    padding-top: 10px !important;
  }
  .footer__list {
    color: #0091dd !important;
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.2em;
    text-transform: capitalize;
    text-decoration: underline 0.15em rgba(0, 0, 0, 0);
    text-underline-offset: 0.2em;
    transition: text-decoration-color 300ms, text-underline-offset 300ms;
    &:hover {
      text-decoration-color: #e19e20;
    }
  }
}
.footer__copyright {
  font-family: var(--font-base);
  font-size: 1rem;
  color: #fff !important;
  background: rgba(0, 0, 0, 0.9) !important;

  .copyright__link {
    color: #0091dd !important;
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;

    &:hover{
        color: #fff!important;
        text-decoration: underline;
    }
  }
}
.arrow__about{
  color: var(--secondary-color);
  margin-bottom: 5px !important;
  margin-right: 10px !important;
  width: 20px;
  text-decoration: none!important;
}