@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Mulish:wght@400;700;900&family=Rubik&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
}

:root {
  --font-base: "Mulish", sans-serif;
  --head-font: 'DM Sans', sans-serif;
  --rubik: 'Rubik', sans-serif;
  --primary-color: #edf2f8;
  --secondary-color: #0091DD;
  --third-color: #fcaf16;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}