.billboard {
  color: #29383f !important;
  text-align: center;
  padding-top: 130px;
  padding-bottom: 80px;
}

.billboard-title {
  font-family: var(--rubik);
  font-weight: bold;
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.container.footer__container {
  width: 60%;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
}

.app__footer-form {
  // width: 100%;
  flex-direction: column;
  // margin: 1rem 2rem;

  div {
    width: 100%;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--primary-color);

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: var(--primary-color);

      font-family: var(--font-base);
      color: var(--secondary-color);
      outline: none;
    }

    textarea {
      height: 170px;
    }

    &:hover {
      box-shadow: 0 0 25px var(--primary-color);
    }
  }

  button {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: var(--secondary-color);

    font-weight: 500;
    color: var(--white-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: #2430af;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}

.TQ_Msg {
  color: #000;
  text-align: center;
  h3 {
    padding-top: 2rem;
    font-weight: 800;
    background: var(--white-color);
    span {
      text-decoration: underline var(--third-color);
      text-underline-offset: 0.4rem;
      color: var(--secondary-color);
    }
  }
}

.find__bg {
  background: #f4faff;
  padding: 15px;
}

.check {
  color: var(--secondary-color);
  margin-bottom: 7px !important;
  float: left;
  width: 20px;
}

.heading__col {
  color: var(--secondary-color);
  font-family: var(--font-base);
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 40px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.text__colo {
  color: #828282 !important;
  font-family: var(--font-base);
  font-style: normal;
  font-size: 1rem !important;
  line-height: 32px;
  h2 {
    margin: 0 0 20px 0;
    color: #000;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #ccc;
    font-size: 22px;
    font-weight: 700;
  }
}

.in-heading {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

.m-t-25 {
  margin-top: 25px !important;
  margin-bottom: 0 !important;
}
.links__phone {
  margin-bottom: 7px !important;

  a {
    text-decoration: none !important;
    color: #828282 !important;
    font-family: var(--rubik);
    font-style: normal;
    font-size: 1rem !important;
    &:hover{
        color: #000!important;
        font-weight: 700;
    }
  }
}

.TQ_Msg {
  color: #000;
  text-align: center;
  h3 {
    padding-top: 2rem;
    font-weight: 800;
    background: var(--white-color);
    span{
      text-decoration: underline var(--third-color);
      text-underline-offset: .4rem;
      color: var(--secondary-color);
    }
  }
}