// WELCOME SECTION STARTS
.welcome__heading {
  font-family: var(--font-base);
  font-weight: 700 !important;
  font-size: 2rem !important;
  text-transform: uppercase !important;
  line-height: 40px;
  letter-spacing: 0.2em;
  color: var(--secondary-color);

  @media only screen and (max-width: 600px) {
    font-size: 1.8rem !important;
  }
}

.welcome__text {
  font-family: var(--font-base);
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.25em;
  text-transform: capitalize;

  @media only screen and (max-width: 600px) {
    font-size: 16px !important;
    line-height: 27px;
    text-align: left !important;
  }

  .know__more {
    font-weight: 700 !important;
    color: #000000 !important;
    text-decoration: underline #e19e20 !important;
    &:hover {
      font-weight: 800 !important;
    }
  }
}

// WELCOME SECTION ENDS

// WORK SECTION STARTS
.work__bg {
  background: #f4faff;
}

.work__heading {
  font-family: var(--head-font);
  color: #000000 !important;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;

  @media only screen and (max-width: 600px) {
    font-size: 2.1rem !important;
    line-height: 40px;
    padding-left: 10%!important;
    padding-right: 10%!important;
  }
}

.text__col .check {
  color: var(--secondary-color);
  margin-bottom: 5px !important;
  margin-right: 10px !important;
  float: left;
  width: 20px;
}

.heading__col {
  color: var(--secondary-color);
  font-family: var(--font-base);
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 40px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media only screen and (max-width: 768px) {
    font-size: 1.5rem!important;
  }
}
.text__col {
  color: #828282 !important;
  font-family: var(--font-base);
  font-style: normal;
  font-weight: 400;
  // font-size: 1.25rem !important;
  line-height: 32px;
}

// WORK SECTION ENDS

// OUR-SERVICES START
.card img {
  //   width: 90px;
  height: 93px;
}
.img__bg {
  background: #f4faff;
  box-shadow: 0px 4px 10px rgba(182, 182, 182, 0.25);
}
.card-body {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
  padding-bottom: 25px;

  .card-title {
    color: var(--secondary-color);
    font-family: var(--font-base);
    font-weight: 700;
    font-size: 1.313rem;
    line-height: 26px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-decoration: underline 0.15em rgba(0, 0, 0, 0);
    text-underline-offset: 0.2em;
    transition: text-decoration-color 300ms, text-underline-offset 300ms;
    &:hover {
      text-decoration-color: #e19e20;
    }

    @media only screen and (max-width: 1081px) {
      font-size: 1.1rem !important;
    }
    @media only screen and (max-width: 950px) {
      font-size: 1rem !important;
    }
    @media only screen and (max-width: 890px) {
      font-size: 0.96rem !important;
    }
  }
  .card-text {
    color: #828282;
    font-family: var(--font-base);
    font-style: normal;
    font-weight: 400;
    font-size: 0.938rem;
    line-height: 22px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    @media only screen and (max-width: 1081px) {
      font-size: 0.8rem !important;
    }
    @media only screen and (max-width: 950px) {
      font-size: 0.91rem !important;
    }
    @media only screen and (max-width: 890px) {
      font-size: 0.919rem !important;
    }
    @media only screen and (min-width: 768px) {
      // font-size: 16px!important;
      letter-spacing: 0.1em;
    }
  }
}
.card__body {
  text-decoration: none !important;
}
// OUR-SERVICES ENDS

// SLIDER STARTS
.carousel-indicators{
  margin-bottom: 0rem !important;
}
// SLIDER ENDS 